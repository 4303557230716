import { useDispatch } from 'react-redux';
import { operations } from '../../redux';
import { useCallback } from 'react';

const useDispatchSetRemoteIframe = () => {
  const dispatch = useDispatch();
  return useCallback(({ remoteIframe }) => {
    dispatch(operations.remoteIframe.setRemoteIframe(remoteIframe));
  }, [dispatch]);
}

export default useDispatchSetRemoteIframe;