import React, { useCallback } from 'react';

import { Form } from 'react-final-form';

import ColorSelector from '../ColorSelector';
import { useTrolleySelectedData } from '../../../hooks/cart';
import NextStepButton from '../../../components/buttons/NextStepButton';
import useDispatchSelectHub from '../../../hooks/useDispatchSelectHub';

const ModelColorForm = ({ children, hubId, step }) => {
  const initialValues = useTrolleySelectedData();
  const dispatchSelectHub = useDispatchSelectHub(hubId);
  const onColorChange = useCallback(
    (color, drawers) => dispatchSelectHub(color, drawers),
    [dispatchSelectHub]);
  const onSubmit = useCallback(() => { }, []);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit}>
          <ColorSelector hubId={hubId} onColorChange={onColorChange} />
          {children}
          <NextStepButton step={step} />
        </form>}
    />
  );
};

export default ModelColorForm;
