import React from 'react';
import PopUp from '../../../components/PopUp';
import useConfigHash from '../../../hooks/useConfigHash';
import useRemoteIframe from '../../../hooks/useRemoteIframe';
import {CreateConfigForm, UpdateConfigForm, CreateConfigFormIframe} from '../Forms';

const SaveConfigPopUp = ({ isOpen, onClose }) => {
  const configHash = useConfigHash();
  const remoteIframe = useRemoteIframe();
  return <PopUp onClose={onClose} isOpened={isOpen}>
    {
      (remoteIframe
        && <CreateConfigFormIframe onClose={onClose} />)
      || (configHash
        && <UpdateConfigForm onClose={onClose} />)
      || <CreateConfigForm onClose={onClose} />
    }
  </PopUp>;
};

export default SaveConfigPopUp;