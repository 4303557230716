import { defineMessages } from 'react-intl';

export default defineMessages({
  ok: {
    id: 'ok',
    defaultMessage: 'Ok'
  },
  nameForYourConfiguration: {
    id: 'nameForYourConfiguration',
    defaultMessage: 'Name for your BETMS configuration'
  },
  registerToGetAQuote: {
    id: 'registerToGetAQuote',
    defaultMessage: "In order to get a quote you must be registered in bahco.com. Pressing 'Ok' will take you there, where you will be able to sign up and save this configuration into your account."
  },
  create: {
    id: 'create',
    defaultMessage: "Save as new"
  },
  update: {
    id: 'update',
    defaultMessage: "Update"
  },
  existingConfigurationModified: {
    id: 'existingConfigurationModified',
    defaultMessage: "The configuration has been modified. What do you want to do?"
  },
  confirm: {
    id: 'confirm',
    defaultMessage: "Confirm"
  },
  confirmNewConfigurationSend: {
    id: 'confirmNewConfigurationSend',
    defaultMessage: "By clicking on Confirm, the selection made will be confirmed"
  },
  confirmed: {
    id: 'confirmed',
    defaultMessage: "Confirmed!"
  }
});
