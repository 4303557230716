import React, { useState } from 'react';
import { injectIntl } from 'react-intl';

import useSummaryJson from '../../../hooks/useSummaryJson';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import messages from './messages';
import styles from './styles.css';

const CreateConfigFormIframe = injectIntl(({ intl }) => {
    const [showButton, setShowButton] = useState(true);
    const summaryJson = useSummaryJson(intl);

    const confirm = () => {
        window.top.postMessage(JSON.stringify(summaryJson), '*');
        setShowButton(!showButton);
    };
    return (
        <div>
            <label>
                {showButton && intl.formatMessage(messages.confirmNewConfigurationSend)}
            </label>
            <div>
                {
                    showButton &&
                    <PrimaryButton componentClassName={styles.button} onClick={confirm}>
                        {intl.formatMessage(messages.confirm)}
                    </PrimaryButton>
                }
                {
                    !showButton &&
                    <p className={styles.confirmed}>{intl.formatMessage(messages.confirmed)}</p>
                }
            </div>
        </div>
    );
});

export default CreateConfigFormIframe;