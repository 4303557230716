import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Orange: {
    id: "Orange",
    defaultMessage: "Orange"
  },
  Black: {
    id: "Black",
    defaultMessage: "Black"
  },
  Blue: {
    id: "Blue",
    defaultMessage: "Blue"
  },
  Grey: {
    id: "Grey",
    defaultMessage: "Grey"
  },
  Red: {
    id: "Red",
    defaultMessage: "Red"
  },
});

const color = (name, hexcode, ralcode) => ({
  id: name.toLowerCase(),
  name,
  hexcode,
  ralcode,
  toString: (intl) => `${intl.formatMessage(messages[name])} ${ralcode}`
});

const orange = color('Orange', '#f16b22', 'RAL2009');
const black = color('Black', '#231f20', 'RAL9005');
const blue = color('Blue', '#1d398d', 'RAL5002');
const grey = color('Grey', '#878581', 'RAL9022');
const red = color('Red', '#b72026', 'RAL3001');

const colorsById = {
  orange: orange,
  black: black,
  blue: blue,
  grey: grey,
  red: red
};

export const colorFromId = id => colorsById[id];

const colorIdFromSkuFragment = skuColorFragment =>
  skuColorFragment.toLowerCase();

export const colorFromSkuFragment = (skuColorFragment = 'ORANGE') =>
  colorFromId(colorIdFromSkuFragment(skuColorFragment));

export const propType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hexcode: PropTypes.string.isRequired,
  ralcode: PropTypes.string.isRequired,
  toString: PropTypes.func.isRequired
});
