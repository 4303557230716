import { combineReducers } from 'redux';

import cartReducer, * as cart from './cart';
import foamsReducer, * as foams from './foams-page';
import productPageReducer, * as productPage from './product-page';
import storeViewReducer, * as storeView from './store-view';
import configHashReducer, * as configHash from './configHash';
import configNameReducer, * as configName from './configName';
import remoteIframeReducer, * as remoteIframe from './remoteIframe';

const reducer = () =>
  combineReducers({
    cart: cartReducer,
    foams: foamsReducer,
    productPage: productPageReducer,
    storeView: storeViewReducer,
    configHash: configHashReducer,
    configName: configNameReducer,
    remoteIframe: remoteIframeReducer,
  });

export default reducer;

export const operations = {
  cart: cart.operations,
  foams: foams.operations,
  productPage: productPage.operations,
  storeView: storeView.operations,
  configHash: configHash.operations,
  configName: configName.operations,
  remoteIframe: remoteIframe.operations
};

export const selectors = {
  cart: cart.selectors,
  foams: foams.selectors,
  productPage: productPage.selectors,
  storeView: storeView.selectors,
  configHash: configHash.selectors,
  configName: configName.selectors,
  remoteIframe: remoteIframe.selectors
};
