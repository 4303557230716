import { useQuery } from '@apollo/react-hooks';

import { useMemo } from 'react';

import { gql } from 'apollo-boost';

import { productFieldsForListItem } from '../queries/productFields';
import { foamFromGraphQlProductInterface } from '../../model/foam';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const query = gql`
  query Foams($searchText: String, $industry: Int, $foamSizes: [Int]){
    betms_foams(
      searchText: $searchText
      industry: $industry
      foamSizes: $foamSizes
    ) {
      items {
        ${productFieldsForListItem}
      }
    }
  }
`;

const useGetFoamsQuery = (
  { searchText, industry, foamSizes } = {
    searchText: '',
    industry: 0,
    foamSizes: []
  }
) => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, {
    variables: { searchText, industry, foamSizes },
    context: {
      headers: {
        Store: storeViewCode
      },
      uri:  config.localizedGraphQlServerUrl(locale)
    }
  });

  const modeledData = useMemo(
    () => {
      const items = (data && data.betms_foams && data.betms_foams.items) || [];
      return items.map(foamFromGraphQlProductInterface);
    },
    [data]
  );

  return { data: modeledData, loading, error };
};

export default useGetFoamsQuery;
