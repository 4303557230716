import React from 'react';

import { SnaPropTypes } from '../../../propTypes';
import { queryHooks } from '../../../../graphql';
import Image, { imageTypes } from '../../../components/Image';

import styles from './styles.css';

const HubDescription = ({ hubId }) => {
  const { data: hubCmsBlocks } = queryHooks.useGetHubCmsBlocks();
  const createCmsBlockMarkup = block => ({
    __html: hubCmsBlocks && hubCmsBlocks[hubId] && hubCmsBlocks[hubId][block]
  });

  return (
    <React.Fragment>
      <Image className={styles.icon} src="logo-storageHUB-bahco_2x.png" alt="" imageType={imageTypes.HUB_ICON} />
      <h3
        className={styles.claim}
        dangerouslySetInnerHTML={createCmsBlockMarkup('claim')}
      />
      <div
        className={styles.description}
        dangerouslySetInnerHTML={createCmsBlockMarkup('description')}
      />
    </React.Fragment>
  );
};
HubDescription.propTypes = {
  hubId: SnaPropTypes.hubId
};

export default HubDescription;
