import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import el from 'react-intl/locale-data/el';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import it from 'react-intl/locale-data/it';
import ja from 'react-intl/locale-data/ja';
import nb from 'react-intl/locale-data/nb';
import nl from 'react-intl/locale-data/nl';
import pl from 'react-intl/locale-data/pl';
import pt from 'react-intl/locale-data/pt';
import ru from 'react-intl/locale-data/ru';
import sk from 'react-intl/locale-data/sk';
import sv from 'react-intl/locale-data/sv';
import tr from 'react-intl/locale-data/tr';
import config from '../config';

const { default: defaultLocale, accepted: acceptedLocales } = config.storeViewLocales;

const localeData = [
  ...cs,
  ...da,
  ...de,
  ...el,
  ...en,
  ...es,
  ...fi,
  ...fr,
  ...it,
  ...ja,
  ...nb,
  ...nl,
  ...pl,
  ...pt,
  ...ru,
  ...sk,
  ...sv,
  ...tr
];

export { acceptedLocales, defaultLocale, localeData };
