import types from './types';

const nextStateByType = {
  [types.SET_REMOTE_IFRAME]: (state, action) => action.payload.remoteIframe
}

const reducer = (state = null, action) => {
  const nextState = nextStateByType[action.type] || (state => state);
  return nextState(state, action);
};

export default reducer;
