import React from 'react';

import { injectIntl } from 'react-intl';

import { Form, Field } from 'react-final-form';

import appConfig from '../../../../config';
import { useLocale } from '../../../hooks/useLocale';
import useSummaryJson from '../../../hooks/useSummaryJson';
import openWindowWithPost from '../../../../utils/openWindowWithPost';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import useConfigHash from '../../../hooks/useConfigHash';
import useConfigName from '../../../hooks/useConfigName';

import messages from '../Forms/messages';
import styles from './styles.css';

const UpdateConfigForm = injectIntl(({ intl, onClose }) => {

  return <React.Fragment>
    <label>
      {intl.formatMessage(messages.existingConfigurationModified)}
    </label>
    <div className={styles.createOrUpdateWrapper}>
      <UpdateMiniForm onClose={onClose} />
      <CreateMiniForm onClose={onClose} />
    </div>
  </React.Fragment>;
});

const UpdateMiniForm = injectIntl(({ intl, onClose }) => {
  const configName = useConfigName();
  const summaryJson = useSummaryJson(intl);
  const configHash = useConfigHash();
  const locale = useLocale();
  const url = `${appConfig.bahcoBaseUrl}/${locale}/betms/postConfig/save`;

  const onUpdateSubmit = () => {
    summaryJson.name = configName;
    const formFields = {
      action: "update",
      config: JSON.stringify(summaryJson),
      config_hash: configHash,
      name: configName
    };
    openWindowWithPost(url, formFields);
    onClose();
  };
  return <Form onSubmit={onUpdateSubmit} render={({ handleSubmit }) => <form onSubmit={handleSubmit}>
    <p className={styles.title}>
      {intl.formatMessage(messages.update)}
    </p>
    <p className={styles.configName}>{configName}</p>
    <PrimaryButton componentClassName={styles.button} type="submit">
      {intl.formatMessage(messages.update)}
    </PrimaryButton>
  </form>} />;
});

const CreateMiniForm = injectIntl(({ intl, onClose }) => {
  const summaryJson = useSummaryJson(intl);
  const locale = useLocale();
  const url = `${appConfig.bahcoBaseUrl}/${locale}/betms/postConfig/save`;

  const onCreateSubmit = ({ name }) => {
    summaryJson.name = name;
    const formFields = {
      action: "create",
      config: JSON.stringify(summaryJson),
      name,
    };
    openWindowWithPost(url, formFields);
    onClose();
  };

  return <Form onSubmit={onCreateSubmit} render={({ handleSubmit }) => <form onSubmit={handleSubmit}>
    <p className={styles.title}>
      {intl.formatMessage(messages.create)}
    </p>
    <Field component="input" name="name" className={styles.searchbox} required placeholder={intl.formatMessage(messages.nameForYourConfiguration)} />
    <SecondaryButton componentClassName={styles.button} type="submit">
      {intl.formatMessage(messages.create)}
    </SecondaryButton>
  </form>} />;
});

export default UpdateConfigForm;