import React from 'react';

import { injectIntl } from 'react-intl';

import { DrawerList } from '../../../components/DrawerList';
import Loader from '../../../components/Loader';
import { PriceWarningText } from '../../../components/PriceWarning';
import { AccessoryList } from '../../../components/ProductList';
import Subtotal from '../../../components/Subtotal';
import { useTrolleyPrice, useAccessoriesSubtotal, useFoamsSubtotal } from '../../../components/Subtotal/hooks';
import { useAccessoriesInCart } from '../../../hooks/cart';
import { useSelectedTrolleyDetails } from '../../../hooks/useSelectedTrolley';

import messages from './messages';
import styles from './styles.css';
import { hubName } from '../../../../model/trolley';

export const SummaryDetails = injectIntl(({ intl }) => {
    const { data, loading } = useSelectedTrolleyDetails();
    const trolleyPrice = useTrolleyPrice();
    return (
        loading
            ? <Loader />
            : <section>
                <p className={styles.model}>
                    <span className={styles.model__title}>{intl.formatMessage(messages.model)}: </span>
                    <span className={styles.model__value}>{hubName(intl, data.hub)}</span>
                </p>
                <p className={styles.color}>
                    <span className={styles.color__title}>{intl.formatMessage(messages.color)}: </span>
                    <span className={styles.color__value}>{data.color.toString(intl)}</span>
                </p>
                <p className={styles.drawers}>
                    <span className={styles.drawers__title}>{intl.formatMessage(messages.drawers)}: </span>
                    <span className={styles.drawers__value}>{data.drawers}</span>
                </p>
                <Subtotal price={trolleyPrice} cssModule={styles} />
                <PriceWarningText cssModule={styles} />
            </section>
    );
});

export const SummaryAccessories = () => {
    const accessoriesInCart = useAccessoriesInCart();
    const accessoriesSubtotal = useAccessoriesSubtotal();
    return (
        <section>
            <AccessoryList products={accessoriesInCart} />
            <Subtotal price={accessoriesSubtotal} cssModule={styles} />
            <PriceWarningText cssModule={styles} />
        </section>
    );
};

export const SummaryFoams = () => {
    const foamsSubtotal = useFoamsSubtotal();
    return (
        <section>
            <DrawerList showDrawerPreview />
            <Subtotal price={foamsSubtotal} cssModule={styles} />
            <PriceWarningText cssModule={styles} />
        </section>
    );
};