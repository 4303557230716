import React from 'react';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { queryHooks } from '../../../graphql';
import { useTrolleySelectedData } from '../../hooks/cart';
import TrolleyPreview from '../../components/TrolleyPreview';

import HubComparator from './HubComparator';
import HubDescription from './HubDescription';
import HubForm from './ModelColorForm';
import styles from './styles.css';
import Price from './PriceFrom';
import Loader from '../../components/Loader';
import { injectIntl } from 'react-intl';
import { hubName } from '../../../model/trolley'
import messages from './messages';
import ErrorBoundary from '../../components/ErrorBoundary';

const clamp = (val, min, max) => Math.min(Math.max(min, val), max);

const Page = injectIntl(({ step = 0, intl }) => {
  const { hubId } = useTrolleySelectedData();
  const e77 = queryHooks.useQueryLowerPriceTrolley('E77');
  const e72 = queryHooks.useQueryLowerPriceTrolley('E72');
  const hubCmsBlocks = queryHooks.useGetHubCmsBlocks();
  const shouldShowPrices = queryHooks.useGetShouldShowPricesQuery();
  const models = [];
  if (e77.loading || e77.data) {
    models.push({ ...e77, name: 'E77' });
  }
  if (e72.loading || e72.data) {
    models.push({ ...e72, name: 'E72' });
  }
  const openTabIndex = clamp(
    models ? models.findIndex(item => item && item.hub === hubId) : 0,
    0,
    2
  );
  return (
    <article className={styles.HubPage}>
      <Tabs
        defaultIndex={openTabIndex}
        className={styles.tabs}
        selectedTabPanelClassName={styles.isSelected}
      >
        <TabList className={styles.tablist}>
          {models.map(
            model =>
              model &&
              <Tab
                key={`tab-${step}-${model.name}`}
                className={styles.tablist__tab}
              >
                {model.loading ? model.name : model.data && model.data.hub && hubName(intl, model.data.hub)}
              </Tab>
          )}
          <Tab className={styles.tablist__tab}>{intl.formatMessage(messages.comparison)}</Tab>
        </TabList>
        {models.map(model =>
          <TabPanel
            key={`tabpanel-${step}-${model.name}`}
            className={styles.tabpanel}
          >
            <ErrorBoundary>
              {model.loading ? <Loader /> : <TrolleyPreview trolley={model.data} />}
              {model.loading || hubCmsBlocks.loading || shouldShowPrices.loading
                ? <Loader />
                : <div className={styles.HubPage__info}>
                  <HubDescription hubId={model.data.hub} />
                  <HubForm {...{ step, hubId: model.data.hub }}>
                    {<Price value={model.data.price} />}
                  </HubForm>
                </div>}
              {/*<HubDetails model={ hub.id } />*/}
            </ErrorBoundary>
          </TabPanel>
        )}
        <TabPanel
          className={`${styles.tabpanel} ${styles.isComparatorTabpanel}`}
        >
          <ErrorBoundary>
            <HubComparator models={models} />
          </ErrorBoundary>
        </TabPanel>
      </Tabs>
    </article>
  );
});

export default Page;
