import React from 'react';

import { DrawerListWithCapacity } from '../../components/DrawerList';
import DrawersDashboard from '../../components/DrawersDashboard';
import Loader from '../../components/Loader';
import { FoamList } from '../../components/ProductList';
import { FoamPage } from '../../components/ProductPage';
import { useFoamsInCart } from '../../hooks/cart';
import { useFilteredFoams } from '../../hooks/foams';
import TabsSelectionAndSelected from '../../components/Tabs/SelectionAndSelected';
import NextStepForm from '../../components/forms/NextStepForm';

import DrawersPopUp from './DrawersPopUp';
import Filters from './Filters';
import styles from './styles.css';

const FoamsPage = ({ step = 3 }) => {
  const { data: foams, loading } = useFilteredFoams();
  const foamsInCart = useFoamsInCart();
  return (
    <React.Fragment>
      <article className={styles.page}>
        <div className={styles.DrawersDashboardContainer}>
          <DrawersDashboard />
        </div>
        <TabsSelectionAndSelected
          selectionPanel={
            <React.Fragment>
              <Filters />
              {loading
                ? <Loader />
                : <FoamList
                    products={foams}
                    interactive
                    showPriceWarning={foams && foams.length > 0}
                  />}
            </React.Fragment>
          }
          selectedPanel={
            <DrawerListWithCapacity
              showPriceWarning={foamsInCart && foamsInCart.length > 0}
            />
          }
        />
        <NextStepForm className={styles.nextStepForm} step={step} />
      </article>
      <FoamPage />
      <DrawersPopUp />
    </React.Fragment>
  );
};

export default FoamsPage;
