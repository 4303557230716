import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import classNames from 'classnames/bind';

import IconHide from '../../icons/IconHide';
import IconShow from '../../icons/IconShow';

import messages from './messages';
import styles from './styles.css';

const ShowHide = ({
  className,
  isTargetVisibleByDefault = true,
  onChange = show => show,
  targetName = '',
  renderText = true
}) => {
  const [targetIsVisible, setTargetVisible] = useState(
    isTargetVisibleByDefault
  );
  const message = targetIsVisible ? messages.hide : messages.show;
  const clickHandler = () => {
    setTargetVisible(visible => !visible);
    onChange(!targetIsVisible);
  };
  const cx = classNames.bind(styles);

  return (
    <button
      className={cx({
        ...(className && { [className]: true }),
        ShowHideButton: true,
        hasText: renderText
      })}
      onClick={clickHandler}
    >
      {targetIsVisible
        ? <IconHide className={styles.ShowHideButton__icon} />
        : <IconShow className={styles.ShowHideButton__icon} />}
      <span
        className={cx({
          'visually-hidden': !renderText,
          ShowHideButton__text: true
        })}
      >
        <FormattedMessage {...message} values={{ targetName }} />
      </span>
    </button>
  );
};

ShowHide.propTypes = {
  isVisibleByDefault: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  targetName: PropTypes.string
};

export default ShowHide;
