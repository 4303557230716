import React from 'react';

import { injectIntl } from 'react-intl';

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import classNames from 'classnames';

import {
  SummaryDetails,
  SummaryAccessories,
  SummaryFoams
} from '../Summaries';
import Loader from '../../../components/Loader';
import { useSelectedTrolley } from '../../../hooks/useSelectedTrolley';
import baseStyles from '../../../components/Tabs/styles.css';
import HubPreview from '../../../components/TrolleyPreview';

import messages from './messages';
import styles from './styles.css';

const SummaryTabs = injectIntl(({ intl }) => {
  const trolley = useSelectedTrolley();
  return (
    <Tabs
      className={classNames(styles.tabs, baseStyles.tabs)}
      forceRenderTabPanel={true}
      selectedTabPanelClassName={baseStyles.isSelected}
    >
      <TabList className={baseStyles.tablist}>
        <Tab className={baseStyles.tablist__tab}>
          {intl.formatMessage(messages.trolley)}
        </Tab>
        <Tab className={baseStyles.tablist__tab}>
          {intl.formatMessage(messages.accessories)}
        </Tab>
        <Tab className={baseStyles.tablist__tab}>
          {intl.formatMessage(messages.fitAndGo)}
        </Tab>
      </TabList>
      <TabPanel className={classNames(styles.splittedTabpanel, baseStyles.tabpanel)}>
        {trolley.loading
          ? <Loader />
          : <HubPreview trolley={trolley.data} />}
        <SummaryDetails />
      </TabPanel>
      <TabPanel className={classNames(styles.splittedTabpanel, baseStyles.tabpanel)}>
        {trolley.loading
          ? <Loader />
          : <HubPreview trolley={trolley.data} />}
        <SummaryAccessories />
      </TabPanel>
      <TabPanel className={baseStyles.tabpanel}>
        <SummaryFoams />
      </TabPanel>
    </Tabs>
  );
});

export default SummaryTabs;
