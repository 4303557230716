import PropTypes from 'prop-types';

import {
  productFromGraphQlProductInterface,
  propTypeRawShapeToExtend
} from './product';

export const FOAM_SIZE_LABELS = ['1/3', '2/3', '3/3'];
export const FOAM_SIZE_VALUES = [1, 2, 3];
const FOAM_SKU_LETTERS = ['E', 'F', 'A'];

const defaultFoamSize = {
  label: FOAM_SIZE_LABELS[0],
  value: FOAM_SIZE_VALUES[0]
}

const foam = ({ product, size = defaultFoamSize }) => ({
  ...product,
  size
});

const foamSizeFromGraphQlProductInterface = item => {
  const r = new RegExp('^FF1([AEF])\\d+$');
  const match = r.exec(item.sku);
  const foamSkuLetter = match && match[1] || 'E';
  const sizeIndex = FOAM_SKU_LETTERS.indexOf(foamSkuLetter);
  const label = FOAM_SIZE_LABELS[sizeIndex];
  const value = FOAM_SIZE_VALUES[sizeIndex];
  return { label, value };
};

export const foamFromGraphQlProductInterface = item =>
  foam({
    product: productFromGraphQlProductInterface(item),
    size: foamSizeFromGraphQlProductInterface(item)
  });

export const propType = PropTypes.shape({
  ...propTypeRawShapeToExtend,
  size: PropTypes.shape({
    label: PropTypes.oneOf(FOAM_SIZE_LABELS).isRequired,
    value: PropTypes.oneOf(FOAM_SIZE_VALUES).isRequired
  }).isRequired
});
