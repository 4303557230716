import React from 'react';

import { createSelector } from 'reselect';

import { useSelector } from 'react-redux';

import SummaryIcon from '../../icons/SummaryIcon';
import { selectors } from '../../../../redux';
import { colorFromId } from '../../../../model/color';

const emptySummary = {
  text: '-'
};

const isEmptyStep = step => step.summary.text === '-';

const createStepSelector = (
  stepSummarySelector,
  requiredStepSelector = () => false
) =>
  createSelector(
    [stepSummarySelector, requiredStepSelector],
    (summary, requiredStep) => {
      const hasPreviousStep = requiredStep !== false;
      return {
        summary: summary || emptySummary,
        disabled: hasPreviousStep && isEmptyStep(requiredStep)
      };
    }
  );

const modelColorStepSelector = createStepSelector(
  createSelector(
    [selectors.cart.getColor, selectors.cart.getHubId],
    (colorId, text) => {
      return (
        colorId &&
        text && {
          color: colorFromId(colorId),
          text
        }
      );
    }
  )
);

const drawersStepSelector = createStepSelector(
  createSelector(
    selectors.cart.getCartDrawers,
    drawers =>
      drawers && {
        text: drawers
      }
  ),
  modelColorStepSelector
);

const accessoriesStepSelector = createStepSelector(
  createSelector(
    selectors.cart.getAccessoriesInCartIds,
    accessories =>
      accessories.length > 0 && {
        text: accessories.length
      }
  ),
  drawersStepSelector
);

const foamsStepSelector = createStepSelector(
  createSelector(
    selectors.cart.getFoamsInCartIds,
    foamIds =>
      foamIds.length > 0 && {
        text: foamIds.length
      }
  ),
  drawersStepSelector
);

const summaryStep = {
  summary: {
    icon: <SummaryIcon />
  },
  disabled: false
};

export const useStepsState = () => [
  useSelector(modelColorStepSelector),
  useSelector(drawersStepSelector),
  useSelector(accessoriesStepSelector),
  useSelector(foamsStepSelector),
  summaryStep
];

export const useIsStepAllowed = stepIndex => {
  const stepStates = useStepsState();
  return stepStates[stepIndex] && stepStates[stepIndex].disabled === false;
};
