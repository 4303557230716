import React, { useCallback } from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';

import { useDispatch } from 'react-redux';

import { Form } from 'react-final-form';

import { queryHooks } from '../../../../graphql';
import { operations } from '../../../../redux';
import Price from '../../../components/Price';
import { PriceWarningText } from '../../../components/PriceWarning';
import { useTrolleySelectedData } from '../../../hooks/cart';
import NextStepButton from '../../../components/buttons/NextStepButton';
import { RadioArray } from '../../../components/forms/Radio';

import { messages } from './messages';
import styles from './styles.css';

const DrawerLabel = ({ trolley }) =>
  <React.Fragment>
    <FormattedMessage
      {...messages.btnNumDrawer}
      values={{ num: trolley.drawers }}
    />
    <Price value={trolley.price} />
  </React.Fragment>;

const Drawers = injectIntl(({ intl, onDrawerChange }) => {
  const { color, hubId } = useTrolleySelectedData();
  const { data } = queryHooks.useQueryTrolleyAll({ color, hubId });
  const radios = data.map(trolley => ({
    label: DrawerLabel({ trolley }),
    value: trolley.drawers
  }));

  return (
    <RadioArray
      cssmodule={styles}
      legend={intl.formatMessage(messages.drawerConfiguration)}
      name="drawers"
      radios={radios}
      onChange={onDrawerChange}
    />
  );
});

const DrawersForm = ({ step }) => {
  const initialValues = useTrolleySelectedData();
  const dispatch = useDispatch();
  const onDrawerChange = useCallback(
    ({ value }) => {
      dispatch(operations.cart.selectDrawers(value));
    },
    [dispatch]
  );
  const onSubmit = useCallback(() => {}, []);
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit }) =>
        <form onSubmit={handleSubmit}>
          <div className={styles.formControlsGroup}>
            <Drawers onDrawerChange={onDrawerChange} />
            {/* (hub.chest) 
            ? <Checkbox 
                name="chest" 
                label={intl.formatMessage(messages.selectTopChest, { drawers: hub.chest.drawers })}
                cssmodule={styles}
                onChange={({checked}) => selectChest(checked)} />
          : '' */}
            <PriceWarningText cssModule={styles} />
          </div>
          <NextStepButton step={step} />
        </form>}
    />
  );
};

export default DrawersForm;
