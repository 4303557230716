import { languageFromStoreViewLocale } from '../../utils/store-view.js';

import cs from './cs.json';
import da from './da.json';
import de from './de.json';
import el from './el.json';
import es from './es.json';
import fi from './fi.json';
import fr from './fr.json';
import int_en from './int_en.json';
import it from './it.json';
import ja from './ja.json';
import nb from './nb.json';
import nl from './nl.json';
import pl from './pl.json';
import pt from './pt.json';
import ru from './ru.json';
import sk from './sk.json';
import sv from './sv.json';
import tr from './tr.json';

const defaultMessages = int_en;

const messagesByLocale = {
  int_en,
  cs: Object.assign({}, defaultMessages, cs),
  da: Object.assign({}, defaultMessages, da),
  de: Object.assign({}, defaultMessages, de),
  el: Object.assign({}, defaultMessages, el),
  en: int_en,
  es: Object.assign({}, defaultMessages, es),
  fi: Object.assign({}, defaultMessages, fi),
  fr: Object.assign({}, defaultMessages, fr),
  it: Object.assign({}, defaultMessages, it),
  ja: Object.assign({}, defaultMessages, ja),
  nb: Object.assign({}, defaultMessages, nb),
  nl: Object.assign({}, defaultMessages, nl),
  pl: Object.assign({}, defaultMessages, pl),
  pt: Object.assign({}, defaultMessages, pt),
  ru: Object.assign({}, defaultMessages, ru),
  sk: Object.assign({}, defaultMessages, sk),
  sv: Object.assign({}, defaultMessages, sv),
  tr: Object.assign({}, defaultMessages, tr),
};

export const getMessagesForStoreViewLocale = storeViewLocale => {
  let messages = messagesByLocale[storeViewLocale];
  if (!messages) {
    messages = messagesByLocale[languageFromStoreViewLocale(storeViewLocale)];
  }
  if (!messages) {
    messages = defaultMessages;
  }
  return messages;
}
