import React, { useState } from 'react';
import {
  useSubtotal,
  useShouldShowSubtotal
} from '../../../components/Subtotal/hooks';
import Subtotal from '../../../components/Subtotal';
import styles from './styles.css';
import { injectIntl } from 'react-intl';
import messages from './messages';
import SaveConfigPopUp from '../SaveConfigPopup';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import useRemoteIframe from "../../../hooks/useRemoteIframe";

const SaveConfigButton = injectIntl(({ intl }) => {
  const [isOpen, setOpen] = useState(false);
  const shouldShowSubtotal = useShouldShowSubtotal();
  const subtotal = useSubtotal();
  const remoteIframe = useRemoteIframe();
  const openConfirmOrderPopUp = () => {
    setOpen(true);
  };
  const closeConfirmOrderPopUp = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <div className={styles.wrapper}>
        {shouldShowSubtotal && <Subtotal price={subtotal} text={messages.total} />}
        <PrimaryButton onClick={openConfirmOrderPopUp}>
          {intl.formatMessage(!remoteIframe ? messages.getAQuote : messages.confirm)}
        </PrimaryButton>
      </div>
      <SaveConfigPopUp isOpen={isOpen} onClose={closeConfirmOrderPopUp} />
    </React.Fragment>
  );
});

export default SaveConfigButton;
