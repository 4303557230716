import React from 'react';

import { Accordion } from 'react-accessible-accordion';

import { ProductDetailsAccordionItem } from '../../components/ProductDetails';
import { ProductTechnicalDetailsAccordionItem } from '../../components/ProductTechnicalDetails';
import {
  useSelectedTrolleyDetails,
  useSelectedTrolley
} from '../../hooks/useSelectedTrolley';
import TrolleyPreview from '../../components/TrolleyPreview';

import DrawersForm from './DrawersForm';
import styles from './styles.css';
import Loader from '../../components/Loader';

const DrawersSelection = ({ step = 1 }) => {
  const trolley = useSelectedTrolley();
  const trolleyDetailed = useSelectedTrolleyDetails();
  return <section className={styles.page}>
    {trolley.loading
      ? <Loader />
      : <TrolleyPreview trolley={trolley.data} />}
    <div>
      <DrawersForm step={step} />
      {trolleyDetailed.loading
        ? <Loader />
        : trolleyDetailed.data &&
        <Accordion>
          <ProductDetailsAccordionItem product={trolleyDetailed.data} />
          <ProductTechnicalDetailsAccordionItem product={trolleyDetailed.data} />
        </Accordion>}
    </div>
  </section>;
};

export default DrawersSelection;
