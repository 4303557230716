import {
  languageFromStoreViewCode,
  storeViewLocaleFromStoreViewCode
} from './utils/store-view';

const baseUrl = '';

const bahcoBaseUrls = {
  devel: 'https://bahco-devel.on4u.com',
  staging: 'https://staging.bahco.com',
  production: 'https://www.bahco.com'
} 

const bahcoBaseUrl = bahcoBaseUrls[process.env.BETMS_SERVER] || bahcoBaseUrls.devel;

const graphQlServerUrl = '/graphql';

// BUG: Graphql doesn't resolve the store correctly. 
// On production the url takes precedence. When not specified it takes int_en. 
// On staging and devel, the header takes precedence.
const localizedGraphQlServerUrl = (storeViewLocale) => `/${storeViewLocale}${graphQlServerUrl}`;

const urlTo = {
  imgs: `${baseUrl}/img`,
  i18nYaml: `${baseUrl}/i18n`,
  sendRequest: storeViewLocale => `${bahcoBaseUrl}/${storeViewLocale}/betms/contact?type=betms`
};

const colors = {
  primario1: '#e6571d',
  primario2: '#292827',
  primario3: '#9b9b9b',
  primario4: '#f3f3f3'
};

const mediaQueries = {
  notDesktop: 'not all and (min-width: 64em)' /* < 1024px */,
  desktop: '(min-width: 64em)' /* >= 1024px */
};

/**
 * size: [width, height]
 */
const imageSizes = {
  hubIcon: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [134, 88]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [134, 88]
    },
    {
      size: [268, 196]
    }
  ],
  hubWithAccessoriesPreview: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [344, 241]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [613, 429]
    },
    {
      size: [1500, 1050]
    }
  ],
  foamPreviewSize1: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [79, 195]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [138, 340]
    },
    {
      size: [1000, 1230]
    }
  ],
  foamPreviewSize2: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [158, 195]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [276, 340]
    },
    {
      size: [1000, 1230]
    }
  ],
  foamPreviewSize3: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [237, 195]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [415, 340]
    },
    {
      size: [1500, 1230]
    }
  ],
  productGallery: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [325, 266]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [370, 303]
    },
    {
      size: [1500, 1230]
    }
  ],
  productListItem: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [88, 72]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [160, 131]
    },
    {
      size: [1500, 1230]
    }
  ],
  technicalAttributeIcon: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [24, 24]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [24, 24]
    },
    {
      size: [45, 45]
    }
  ],
  trolleyPreview: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [228, 285]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [424, 530]
    },
    {
      size: [640, 800]
    }
  ],
};

const drawersPopUpTypes = {
  addTo: 'addTo',
  moveTo: 'moveTo'
};

const draggableItemTypes = {
  foamFromList: 'foam',
  foamPreview: 'foamPreview'
};

const millisecondsToShowSendRequestInfo = 1000 * 60 * 2;

const routerPaths = {
  steps: ['trolley', 'drawers', 'accessories', 'foams', 'confirm']
};

const storeViewCodes = {
  accepted: [
    'bahco_afr_en',
    'bahco_afr_fr',
    'bahco_afr_pt',
    'bahco_ar_es',
    'bahco_at_de',
    'bahco_au_en',
    'bahco_be_fr',
    'bahco_be_nl',
    'bahco_ch_de',
    'bahco_ch_fr',
    'bahco_cl_es',
    'bahco_cz_cs',
    'bahco_de_de',
    'bahco_dk_da',
    'bahco_es_es',
    'bahco_fi_fi',
    'bahco_fr_fr',
    'bahco_gb_en',
    'bahco_gr_el',
    'bahco_int_en',
    'bahco_it_it',
    'bahco_jp_ja',
    'bahco_nl_nl',
    'bahco_no_nb',
    'bahco_nz_en',
    'bahco_pl_pl',
    'bahco_pt_pt',
    'bahco_ru_ru',
    'bahco_se_sv',
    'bahco_sk_sk',
    'bahco_tr_tr'
  ],
  default: 'bahco_int_en'
};

const storeViewLocales = {
  accepted: storeViewCodes.accepted.map(storeViewLocaleFromStoreViewCode),
  default: storeViewLocaleFromStoreViewCode(storeViewCodes.default)
};

const languages = {
  accepted: Object.values(
    storeViewCodes.accepted.reduce(
      (res, code) => ((res[code] = languageFromStoreViewCode(code)), res),
      {}
    )
  ),
  default: languageFromStoreViewCode(storeViewCodes.default)
};

const gtmArgs = {
  gtmId: 'GTM-MKM3HT4',
};

export default {
  baseUrl,
  bahcoBaseUrl,
  colors,
  draggableItemTypes,
  drawersPopUpTypes,
  graphQlServerUrl,
  localizedGraphQlServerUrl,
  imageSizes,
  languages,
  mediaQueries,
  millisecondsToShowSendRequestInfo,
  routerPaths,
  storeViewCodes,
  storeViewLocales,
  urlTo,
  gtmArgs,
};
