import React, { useState } from 'react';

import { injectIntl } from 'react-intl';

import appConfig from '../../../../config';
import { imageTypes } from '../../../components/Image';
import useMedia from '../../../hooks/useMedia';
import { useSelectedTrolley } from '../../../hooks/useSelectedTrolley';
import ShowHide from '../../../components/buttons/ShowHide';
import TrolleyPreview from '../../../components/TrolleyPreview';

import messages from './messages';
import styles from './styles';
import Loader from '../../../components/Loader';

const HubWithAccessoriesPreview = ({ intl }) => {
  const [mobile] = appConfig.imageSizes[
    imageTypes.HUB_WITH_ACCESSORIES_PREVIEW
  ];
  const matchesMobile = useMedia(mobile.mediaQuery);
  const trolley = useSelectedTrolley();
  const [isVisible, setVisible] = useState(true);
  const onButtonChange = show => {
    setVisible(show);
  };
  return (
    <div className={styles.previewContainer}>
      <ShowHide
        className={styles.previewToggleButton}
        isTargetVisibleByDefault={true}
        onChange={onButtonChange}
        targetName={intl.formatMessage(messages.preview)}
      />
      {trolley.loading
        ? <Loader />
        : <TrolleyPreview trolley={trolley.data} isHidden={matchesMobile && !isVisible} />}
    </div>
  );
};

export default injectIntl(HubWithAccessoriesPreview);
