import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Step from '../Step';
import config from '../../../../config';
import { useLocalizeRouterPathCallback } from '../../../hooks/useLocale';

import styles from './Stepper.module.css';
import { useStepsState } from './hooks';
import messages from './messages';

const stepUrls = config.routerPaths.steps;

const makeStepIntlTitles = intl =>
  intl
    ? [
        intl.formatMessage(messages.step_model),
        intl.formatMessage(messages.step_drawers),
        intl.formatMessage(messages.step_accessories),
        intl.formatMessage(messages.step_foams),
        intl.formatMessage(messages.step_summary)
      ]
    : new Array(5);

export const useSteps = (intl = false) => {
  const states = useStepsState();
  const localizeRouterPath = useLocalizeRouterPathCallback();
  return useMemo(
    () => {
      const stepTitles = makeStepIntlTitles(intl);
      return states.map((step, index) => ({
        ...step,
        ...{ url: localizeRouterPath(stepUrls[index]) },
        ...{ title: stepTitles[index] }
      }));
    },
    [intl, states, localizeRouterPath]
  );
};

export const useStepTitle = (stepIndex, intl) => {
  const steps = useSteps(intl);
  return steps[stepIndex] && steps[stepIndex].title;
};

const Stepper = ({ intl }) => {
  const steps = useSteps(intl);
  return (
    <ol className={styles.stepper}>
      {steps.map((item, i) =>
        <Step
          key={i}
          disabled={item.disabled}
          index={i}
          title={item.title}
          summary={item.summary}
          url={item.url}
        />
      )}
    </ol>
  );
};

Stepper.propTypes = {
  step: PropTypes.number
};

export { useIsStepAllowed } from './hooks';

export default injectIntl(Stepper);
