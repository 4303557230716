import { defineMessages } from "react-intl";

export default defineMessages({
  trolley: {
    id: "trolley",
    defaultMessage: "Trolley"
  },
  accessories: {
    id: "accessories",
    defaultMessage: "Accessories"
  },
  fitAndGo: {
    id: "fitAndGo",
    defaultMessage: "Foams"
  }
});