import React, { memo } from 'react';

import PropTypes from 'prop-types';

import { queryHooks } from '../../../../graphql';
import useSanitizeDangereouslySetInnerHtml from '../../../hooks/useSanitizeDangerouslySetInnerHtml';
import TrolleyPreview from '../../../components/TrolleyPreview';

import styles from './HubComparatorCms.css';
import Loader from '../../../components/Loader';
import { removeStyleAttribute } from '../../../../utils/html';
import { compose } from 'redux';

const decorateCheckMark = html => html && html.replace(/<td>x<\/td>/gi, '<td class="comparison-table-check-mark">&#10003;</td>');

const processComparisonTableCmsBlock = compose(
  useSanitizeDangereouslySetInnerHtml,
  decorateCheckMark,
  removeStyleAttribute
)

const HubComparator = ({ models }) => {
  const modelsLoading = models ? models.some(t => t.loading === true) : true;
  const { data, loading } = queryHooks.useGetComparisonTableCmsBlockQuery();
  const sanitizedTable = processComparisonTableCmsBlock(data);
  return (
    <React.Fragment>
      {modelsLoading
        ? <Loader containerClassName={styles.previews} />
        : <div className={styles.previews}>
            {models &&
              models.map(
                (trolley, index) =>
                  trolley.data &&
                  <TrolleyPreview
                    cssModule={styles}
                    trolley={trolley.data}
                    key={`hub-comparator-preview-${index}`}
                  />
              )}
          </div>}
      {loading
        ? <Loader />
        : <div className={styles.comparisonTable} {...sanitizedTable} />}
    </React.Fragment>
  );
};

HubComparator.propTypes = {
  models: PropTypes.array.isRequired
};

export default memo(HubComparator);
