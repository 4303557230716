import { useAccessoriesInCartForHub, useTrolleySelectedData } from "./cart";
import { useCallback } from "react";
import { operations } from "../../redux";
import { useDispatch } from 'react-redux';

const useDispatchSelectHub = (hubId) => {
    const accessoryIds = useAccessoriesInCartForHub(hubId).map(item => item.id);
    const selectedTrolleyData = useTrolleySelectedData();
    const dispatch = useDispatch();
    return useCallback((color, drawers) => {
        dispatch(operations.cart.selectHub({ hubId, color, accessoryIds }));
        if(selectedTrolleyData.hubId === hubId && drawers) {
            dispatch(operations.cart.selectDrawers(drawers));
        }
    }, [accessoryIds, dispatch, hubId, selectedTrolleyData.hubId]);
};

export default useDispatchSelectHub;