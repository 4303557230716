import { defineMessages } from "react-intl";

export const messages = defineMessages({
  btnNumDrawer: {
    id: 'drawers.btn.num',
    defaultMessage: '{num} drawers'
  },
  drawerConfiguration: {
    id: 'DrawerConfiguration',
    defaultMessage: 'Drawer Configuration'
  },
  selectTopChest: {
    id: 'drawers.chestOption',
    defaultMessage: 'top-chest {drawers} drawers'
  }
});