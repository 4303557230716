import ApolloClient, { gql } from 'apollo-boost';

import fetch from 'whatwg-fetch';

import config from '../config';

import useGetAccessoriesQuery from './hooks/useGetAccessoriesQuery';
import useGetAvailableColorsForHub from './hooks/useGetAvailableColorsForHub';
import useGetAvailableIndustriesQuery from './hooks/useGetAvailableIndustriesQuery';
import useGetComparisonTableCmsBlockQuery from './hooks/useGetComparisonTableCmsBlockQuery';
import useGetFoamsQuery from './hooks/useGetFoamsQuery';
import useGetHubCmsBlocks from './hooks/useGetHubCmsBlocks';
import { useGetShouldShowPricesQuery, useGetCurrencyCodeQuery } from './hooks/useQueryStoreConfig';
import {
  useQueryAccessory,
  useQueryAccessoryAll,
  useQueryFoam,
  useQueryFoamAll
} from './hooks/useQueryProduct';
import {
  useQueryLowerPriceTrolley,
  useQueryTrolley,
  useQueryTrolleyAll
} from './hooks/useQueryTrolley';

const serverUri = config.graphQlServerUrl;

export const client = new ApolloClient({
  fetchOptions: { fetch },
  headers: {
    Store: 'bahco_de_de'
  },
  uri: serverUri
});

export const getConfig = ({ client, storeViewCode, configHash }) => {
  return client.query({
    query: gql`query($configHash: String!) {
      betms_configuration(hash: $configHash) {
        configuration
      }
    }`,
    variables: {
      configHash
    },
    context: {
      headers: {
        Store: storeViewCode
      }
    }
  }).then(({ data }) => {
    return data && data.betms_configuration && data.betms_configuration.configuration && JSON.parse(data.betms_configuration.configuration) || null;
  });
}

export const queryHooks = {
  useGetAccessoriesQuery,
  useGetFoamsQuery,
  useGetAvailableColorsForHub,
  useGetComparisonTableCmsBlockQuery,
  useGetHubCmsBlocks,
  useGetShouldShowPricesQuery,
  useGetCurrencyCodeQuery,
  useQueryAccessory,
  useQueryAccessoryAll,
  useQueryFoam,
  useQueryFoamAll,
  useQueryLowerPriceTrolley,
  useQueryTrolley,
  useQueryTrolleyAll,
  useGetAvailableIndustriesQuery
};
