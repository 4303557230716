import React from 'react';

import { queryHooks } from '../../../graphql';
import Loader from '../../components/Loader';
import { AccessoryList } from '../../components/ProductList';
import { AccessoryPage } from '../../components/ProductPage';
import { useAccessoriesInCart, useTrolleySelectedData } from '../../hooks/cart';
import TabsSelectionAndSelected from '../../components/Tabs/SelectionAndSelected';
import NextStepForm from '../../components/forms/NextStepForm';

import HubWithAccessoriesPreview from './HubWithAccessoriesPreview';
import styles from './styles.css';

const AccessoriesPage = ({ step = 2 }) => {
  const { hubId } = useTrolleySelectedData();
  const { data, loading } = queryHooks.useGetAccessoriesQuery(hubId);
  const accessoriesInCart = useAccessoriesInCart();
  return (
    <React.Fragment>
      <article className={styles.page}>
        <HubWithAccessoriesPreview />
        <TabsSelectionAndSelected
          selectionPanel={
            loading
              ? <Loader />
              : <AccessoryList
                  products={data}
                  interactive
                  showPriceWarning={true}
                />
          }
          selectedPanel={
            <AccessoryList
              products={accessoriesInCart}
              interactive
              showPriceWarning={true}
            />
          }
        />
        <NextStepForm className={styles.nextStepForm} step={step} />
      </article>
      <AccessoryPage />
    </React.Fragment>
  );
};

export default AccessoriesPage;
