import { defineMessages } from "react-intl";

export default defineMessages({
  show: {
    id: "show",
    defaultMessage: "show {targetName}"
  },
  hide: {
    id: "hide",
    defaultMessage: "hide {targetName}"
  }
});