import { useMemo } from 'react';

import DOMPurify from 'dompurify';

const sanitize = html => ({
  __html: html && DOMPurify.sanitize(html.__html || html)
});

export default html => {
  const sanitized = useMemo(() => sanitize(html), [html]);
  return {
    dangerouslySetInnerHTML: sanitized
  }
}