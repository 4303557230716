import React, { Fragment, useEffect, useCallback } from 'react';

import { defineMessages, injectIntl } from 'react-intl';

import { ToastContainer, toast } from 'react-toastify';

import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useParams
} from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import config from '../config';

import styles from './index.css';

import SendRequestInfo from './components/SendRequestInfo';
import Stepper, { useSteps } from './components/stepper';
import useDispatchSetConfig from './hooks/useDispatchSetConfig';
import useDispatchSetRemoteIframe from './hooks/useDispatchSetRemoteIframe';
import { useLocale, useDispatchChangeLocale } from './hooks/useLocale';
import Accessories from './pages/accessories';
import Drawers from './pages/drawers';
import Foams from './pages/foams';
import Hub from './pages/hub';
import Summary from './pages/summary';
import CloseButton from './components/buttons/Close';
import SendRequestButton from './components/buttons/SendRequestButton';
import useRemoteIframe from "./hooks/useRemoteIframe";

const messages = defineMessages({
  appName: {
    id: 'app.name',
    defaultMessage: 'BETMS Configurator'
  }
});

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

const useLoadConfigFromHash = () => {
  const queryString = useQueryString();
  const dispatchSetConfig = useDispatchSetConfig();
  const configHash = queryString.get('config_hash');
  if (configHash) {
    dispatchSetConfig({ configHash });
  }
};

const useLoadIframeParamFromUrl = () => {
    const queryString = useQueryString();
    const dispatchSetRemoteIframe = useDispatchSetRemoteIframe();
    const remoteIframe = !!queryString.get('remote_iframe');
    if (remoteIframe) {
        dispatchSetRemoteIframe({ remoteIframe });
    }
}

const useSaveLocaleUrlParamToRedux = () => {
  const { locale } = useParams();
  const reduxLocale = useLocale();
  const dispatchChangeLocale = useDispatchChangeLocale();

  const hasLocaleChanged = (locale, reduxLocale) =>
    locale && locale !== reduxLocale;

  if (hasLocaleChanged(locale, reduxLocale)) {
    dispatchChangeLocale(locale);
  }

  return locale;
};

const useShowSendARequestInfo = () => {
  const toastId = 'send-request-info';
  return useCallback(
    () => {
      if (toast.isActive(toastId)) return;
      toast(<SendRequestInfo />, {
        toastId: toastId,
        position: 'bottom-right',
        autoClose: false,
        bodyClassName: styles.toast__body,
        closeButton: <CloseButton />,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    },
    [toastId]
  );
};

const LocaleSwitch = () => {
  useLoadConfigFromHash();
  useLoadIframeParamFromUrl();
  return (
    <Switch>
      <Route path="/:locale">
        <App />
      </Route>
      <Route path="/">
        <Redirect to="/int_en" />
      </Route>
    </Switch>
  );
};

const PageSwitch = injectIntl(({ intl }) => {
  const steps = useSteps(intl);
  const isStepAllowed = index => !steps[index].disabled;
  const getStepUrl = index => `${steps[index].url}`;

  return (
    <Switch>
      <Route path={getStepUrl(0)} component={Hub} />
      {isStepAllowed(1)
        ? <Route path={getStepUrl(1)} component={Drawers} />
        : <Redirect to={getStepUrl(0)} />}
      {isStepAllowed(2)
        ? <Route path={getStepUrl(2)} component={Accessories} />
        : <Redirect to={getStepUrl(1)} />}
      {isStepAllowed(3)
        ? <Route path={getStepUrl(3)} component={Foams} />
        : <Redirect to={getStepUrl(2)} />}
      {isStepAllowed(4)
        ? <Route path={getStepUrl(4)} component={Summary} />
        : <Redirect to={getStepUrl(3)} />}
      <Route path="/">
        <Redirect to={getStepUrl(0)} />
      </Route>
    </Switch>
  );
});

const App = injectIntl(({ intl }) => {
  const locale = useSaveLocaleUrlParamToRedux();
  const showSendARequestInfo = useShowSendARequestInfo();
  const remoteIframe = useRemoteIframe();

  if (!remoteIframe) {
      useEffect(
          () => {
              setTimeout(
                  showSendARequestInfo,
                  config.millisecondsToShowSendRequestInfo
              );
              document.title = intl.formatMessage(messages.appName);
          },
          [intl, showSendARequestInfo]
      );
  }

  const isLocaleAccepted = locale =>
    locale && config.storeViewLocales.accepted.includes(locale);

  return isLocaleAccepted(locale)
    ? <Fragment>
      <div className={styles.mainHeader}>
        <span className={styles.mainHeader__title}>Trolley Configurator</span>
          {
              !remoteIframe
              && <SendRequestButton
                    componentClassName={styles.mainHeader__sendRequest}
                />
          }
        {/* <LanguageSelector className="lang"/> */}
      </div>
      <nav className={styles.nav}>
        <Stepper />
      </nav>
      <main className={styles.main}>
        <Switch>
          <Route path="/:locale">
            <PageSwitch />
          </Route>
          <Route path="/">
            <Redirect to="/int_en" />
          </Route>
        </Switch>
      </main>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
    </Fragment>
    : <Redirect to="/int_en" />;
});

export default LocaleSwitch;
