import types from './types';

const setRemoteIframe = remoteIframe => ({
  type: types.SET_REMOTE_IFRAME,
  payload: {
    remoteIframe
  }
});

export default {
  setRemoteIframe,
};
