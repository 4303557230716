import PropTypes from 'prop-types';

import { colorFromSkuFragment } from './color';
import { propType as colorPropType } from './color';
import { productFromGraphQlProductInterface, propTypeRawShapeToExtend } from './product';
import { defineMessages } from 'react-intl';

const messages = defineMessages({  
  E72: {
    id: "E72",
    defaultMessage: "E72 \"Basic\""
  },
  E77: {
    id: "E77",
    defaultMessage: "E77 \"Premium\""
  },
});

export const hubName = (intl, hub) => intl.formatMessage(messages[hub]);

const trolley = ({ product, sku }) => {
  return {
    ...product,
    ...extractDataFromSku(sku)
  };
};

const extractDataFromSku = sku => {
  const r = new RegExp('^\\d{2}(\\d{2})K(\\d)(\\w*)$', 'gi');
  const match = r.exec(sku);
  if (!match) throw Error('This SKU is not referred to a trolley');
  const hub = 'E' + match[1];
  const drawers = match[2] * 1;
  const color =
    match.length > 3 && match[3] !== ''
      ? colorFromSkuFragment(match[3])
      : colorFromSkuFragment();
  return {
    color,
    drawers,
    hub,
  };
};

export const trolleyFromGraphQlProductInterface = item =>
  trolley({
    product: productFromGraphQlProductInterface(item),
    sku: item.sku,
  });

export const propType = PropTypes.shape({
  ...propTypeRawShapeToExtend,
  color: colorPropType,
  drawers: PropTypes.number,
  hub: PropTypes.oneOf(['E72', 'E77']),
});
